.card-head{
  background: var(--card-background);
  width: 100%;
  padding-bottom: 30px;
  border-radius: 8px;
}
.card-img {
  height: 290px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.movies-grid {
  padding: 0px 30px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  margin-top: 40px;
  gap: 20px;
  z-index: 9999;
}

.card-title {
  font-size: 16px;
  font-weight: 400;
  text-transform: capitalize;
  padding-right: 4px;
  text-align: center;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.episode-total{
  border-radius: 3px;
  justify-content: right;
  display: flex;
  gap: 10px;
  padding-right: 10px;
  padding-bottom: 5px;
  font-size: 13px;
  margin-top: 10px;
}
.episode-total span{
  background-color: var(--button-color);
  padding: 0px 7px;
  position: relative;
  border-radius: 3px;
  color: var(--text-color);
}
.loadmore-recent {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 50px;
  padding-bottom: 40px;
}

.loadmore-recent .loadmore {
  padding: 10px 20px;
  background-color: var(--button-color);
  color: var(--text-color);
  font-size: 18px;
  border-radius: 9px;
  font-weight: 500;
  transition: .3s all ease-in-out;
}

.loadmore-recent .loadmore:hover {
  background: transparent;
  border: 2px solid var(--button-color);
  box-shadow: 2px 10px 90px var(--button-color);
}
.bookmark-icon{
  cursor: pointer;
  position: absolute;
  float: right;
}
.bookmark-icon i{
  font-size: 25px;
  display: flex;
  color: var(--button-color);
  background-color: var(--bookmark-bg);
  padding: 20px 20px; 
  -webkit-clip-path: circle(34.7% at 50% 50%);
  clip-path: circle(34.7% at 50% 50%); 
}



@media(max-width:950px){
  .card-img{
    height: 220px;
  }
  .movies-grid{
    grid-template-columns: repeat(auto-fill,minmax(165px,1fr));
  }
}

@media(max-width:610px){
  .card-head{
    height: 300px;
  }
  .card-img{
    height: 220px;
    width: 100%;
  }
  .movies-grid{
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    /* padding: 0px 40px; */
    gap:20px;
  }
}

@media(max-width:550px){
  .card-head{
    height: 300px;
  }
  .movies-grid{
    padding: 0px 20px;
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    height: auto;
  }
}
@media(max-width:480px){
  .card-head h5{
    font-size: 14px;
    font-weight: 500;
  }
  .card-img{
    height: 270px;
  }
  .movies-grid{
    grid-template-columns: repeat(auto-fill, minmax(140px,1fr));
  }
}

@media(max-width:470px){
  .card-head{
    height: 300px;
  }
  .card-img{
    height: 220px;
  }
  .card-head h5{
    font-size: 12px;
  }
  .movies-grid{
    grid-template-columns:repeat(auto-fill,minmax(140px,1fr)) ;
  }
  .loadmore-recent .loadmore {
    font-size: 12px;
  }
}
@media(max-width:355px){
  .movies-grid{
    grid-template-columns: repeat(auto-fill,minmax(100px,1fr));
  }
}

