.spinner {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 15%;
  }
  
  .genres {
    font-weight: 200;
    color: var(--text-color);
    font-size: 12px;
    letter-spacing: 2px;
  }
  
  .movies {
    position: relative;
    top: 40px;
    margin: auto;
  }
  
  .filter-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 50px;
    border-radius: 20px;
    margin-bottom: 30px;
  }
  .filter-bar.genre{
    background-color: var(--genre-filer-bar);
    padding: 20px 50px 20px;
    width: 90%;
    margin: auto;
  }
  .filter-bar .heading h3{
    font-size: 50px;
    margin-top: -15px;
  }
  .filter-bar .heading h2{
    font-size: 30px;
    font-weight: 500;
  }
  select{
    background:transparent;
    color: #fff;
    font-size: 30px;
    display: flex;
  }
  option {
    border-radius: 15px;
    background-color: var(--genre-background);
    color: var(--text-color);
  }
  @media(max-width:1000px){
    .filter-bar .heading h3{
      font-size: 35px;
    }
  }

  @media(max-width: 700px){
    .filter-bar .heading h2{
      font-size: 30px;
    }
  }
  @media(max-width: 600px){
    .filter-bar .heading h3{
      font-size: 35px;
    }

    .filter-bar .heading h2{
      font-size: 23px;
    }
  }

  @media(max-width:410px){
    .filter-bar .heading h3{
      font-size: 30px;
    }
  }
  

  @media(max-width: 560px){
    .filter-bar{
      padding: 0px 20px;
    }
    .filter-bar select {
      font-size: 20px;
      margin: 0px;
    }
    .filter-bar .heading h2{
      font-size:  20px;
    }
  }
  @media(max-width: 418px){
    .filter-bar select {
      font-size: 12px;
      margin: 0px;
    }
    .filter-bar .heading h2{
      font-size:  18px;
    }
  }
  @media(max-width:450px){
    .filter-bar .heading h2{
      font-size: 17px;
    }
  }