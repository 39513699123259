.upcoming-season{
    max-width: 95%;
    margin: auto;
}
.upcoming-season h1{
    padding-bottom: 30px;
}
.genre-upcoming{
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    overflow-x: hidden;
}
.seasons{
    width: 100%;
    display: grid;
    gap: 20px;
    grid-template-columns: repeat(4, 1fr);
}
.details-seasons{
    display: flex;
    gap: 10px;
}
.season-box{
    padding: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color:var(--upcoming-season-bg);
    border-radius: 10px;
    max-height: 600px;
    overflow-y: scroll;
    padding-bottom: 30px;
}
.season-box h2{
    padding-bottom: 10px;
}
.season-box::-webkit-scrollbar{
    display: none;
}
.content{
    display: flex;
    gap: 10px;
}
.content>a>img{
    width: 100px;
    height: auto;
}
.content>.text{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

@media screen and (max-width: 1400px){
    .upcoming-season{
        width: 90%;
    }
    .seasons{
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 900px){
    .seasons{
        grid-template-columns: repeat(1, 1fr);
    }
}

@media screen and (max-width: 500px){
    .content>img{
        width: 40%;
    }
    .content .text{
        font-size: 10px;
    }
}
