.lastwatch {
  display: none;
  padding-bottom: 30px;
}

.lastwatch.active {
  display: block;
}
.lastwatch-heading{
  font-size: 30px;
}
.lastwatch-close {
  position: absolute;
  left: auto;
  background: hsl(225, 25%, 7%);
  padding: 10px 20px;
  -webkit-clip-path: circle(34.7% at 50% 50%);
  clip-path: circle(34.7% at 50% 50%);
  cursor: pointer;
}

.lastwatch-close i {
  font-size: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.lastwatch-head{
  background: #162032;
  width: 200px;
  height: 350px;
  display: grid;
  border-radius: 4px;
}
.lastwatch-img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.continue-icon{
  font-size: 35px;
  color: rgb(33,150,243);
}
.lastwatch-title {
  font-size: 16px;
  font-weight: 400;
  margin-top: 10px;
  text-transform: capitalize;
  padding: 5px 10px 20px;
  text-align: center;
  max-width: 200px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis;
}
.last-ep{
  color:  rgb(33,150,243);
}


