* {
  margin: 0;
  padding: 0;
  text-decoration: none;
  font-family: poppins;
  list-style-type: none;
  -webkit-tap-highlight-color: transparent;
}
.art-setting-quality {
  display: block !important;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* scroll bar */
html {
  scroll-behavior: smooth;
  font-family: poppins;
  scrollbar-color: rgb(41, 109, 234);
  scrollbar-width: 0.5rem;
}


h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

body{
  background: var(--background-color);
  max-height: 100%;
  min-height: 100vh;
  width: 100%;
  color: var(--text-color);
}

img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  pointer-events: none;
}

input,
button,
select {
  font: inherit;
  background-color: none;
  border: none;
  outline: none;
}

button {
  color: var(--text-color);
  cursor: pointer;
}

a {
  text-decoration: none;
  display: block;
  color: var(--text-color);
}

::-webkit-scrollbar {
  background: transparent;
  width: 6px;
}

::-webkit-scrollbar-thumb {
  background-color:var(--scroll-bar);
  border-radius: 14px;
}



.react-loading-skeleton {
  display: block;
  animation: pulse 1.5s ease-in-out 0.5s infinite;
  transform: translateZ(0);
  background: linear-gradient(-90deg, #000000 0%, #444 50%, #444 100%);
  background-size: 400% 400%;
}

@keyframes pulse {
  0% {
    background-position: 0% 0%;
  }
  100% {
    background-position: -100% 0%;
  }
}

