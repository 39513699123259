.swiper {
  width: 95%;
  height: 450px;
  border-radius: 14px;
  margin-top: 20px;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  border-radius: 14px;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  z-index: 9999;
}

.swiper-slide::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.65);
  filter: drop-shadow(90px);
}

.banner-text {
  position: inherit;
  bottom: 200px;
  color: #fff;
  transform: translateY(-50%);
  z-index: 999;
  padding-left: 40px;
  padding-right: 40px;
}




.banner-text h4 {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 20px;
  overflow: hidden;
}

.watch {
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 14px 30px;
  line-height: 1.5;
  background-color: rgb(33,150,243);
  border-radius: 10px;
  color: #fff;
  font-weight: 700;
  margin-top: 10px;
  transition: .5s ease-in-out;
}

@media(max-width:1000px){
  .swiper{
    height: 350px;
  }
  .banner-text{
    bottom: 150px;
  }
  .banner-text h4{
    font-size: 30px;
    max-width: 700px;
  }
  .watch{
    padding: 9px 20px;
  }
}
@media(max-width: 850px){
  .swiper{
    height: 300px;
  }
  .banner-text h4{
    font-size: 25px;
  }
  .watch{
    padding: 15px 20px;
    font-size: 12px;
  }
}
@media(max-width:550px){
  .swiper{
    height: 200px;
  }
  .banner-text{
    bottom: 100px;
  }
  .banner-text h4{
    font-size: 14px;
    max-width: 500px;
  }
  .watch{
    font-size: 7px;
    font-size: 10px;
    margin-top: -10px;
    padding: 10px 20px;
  }
}
@media (max-width:400px){
  .swiper{
    height: 180px;
  }
}
