.chat-container {
    height: 85vh;
    width: 100%;
    position: relative;
    color: var(--text-color);
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.chat-container-heading {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 19px;
    text-align: center;
    margin-bottom: 30px;
    margin-top: 10px;
}
.chat-container-heading p {
    font-size: 11px;
    padding: 10px;
    font-weight: 500;
    opacity: .9;
}

.chat-body {
    height: 90%;
    overflow: auto;
    width: 100%;
    max-width: 56rem;
    min-width: 20rem;
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
    align-items: center;
}

.chat-input {
    width: 80%;
    /* min-width: 20rem; */
    align-items: center;
}
.chat-main-details{
    display: flex;
    flex-direction: column;
    gap: 4;
}

.ai-style {
    margin-right: auto;
}

.chat-aiandhuman {
    background: var(--chatbot-chat);
    border-radius: 8px;
    max-width: 80%;
    place-self: end;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    overflow-wrap: break-word;
    margin-bottom: 10px;
}

.chat-message {
    white-space: pre-wrap;
    font-size: 20px;
    font-weight: 500;
}

.height {
    height: 20px;
}

.input-main {
    width: 100%;
    background: var(--chatbot-chat);
    max-height: 10rem;
    border-radius: 8px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    overflow: auto;
    position: relative;
}
.input-main textarea{
    resize: none;
    outline: none;
    border: none;
    background: transparent;
    width: 90%;
    color: var(--text-color);
    font-size: 18px;
    font-weight: 500;
}
.loader-input{
    margin: auto;
    width: 2rem;
}
.input-submit{
    position: absolute;
    top: 1rem;
    right: 0.75rem;
    cursor: pointer;
    transition: .2s ease-in;
    font-size: 30px;
    font-weight: 300;
}
.input-submit:hover{
    transform: scale(1.3);
}

.text-copy{
    border: none;
    background: transparent;
    position: relative;
    float: right;
}
.text-copy.copied{
    display: none;
}
@media(max-width:400px){
     .chat-container{
        height: 80vh;
    }
    .chat-container-heading {
        font-size: 14px;
    }
    .chat-input{
        width: 92%;
    }
}
