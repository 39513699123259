.details {
  width: 100%;
  display: flex;
  padding: 2rem 4rem;
  flex-direction: column;
}

.list-box.details {
  display: none;
}

.anime-trailer{
  display: flex;
  justify-content: left;
  align-items: left;
  padding: 30px;
}
.anime-trailer iframe{
  max-width: 700px;
  max-height: 500px;
  width: 500px;
  height: 300px;
}

.anime-details {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.anime-img .anime-image{
  width: 310px;
  height: 420px;
  margin: auto;
  border-radius: 14px;
}


.anime-info {
  height: 100%;
  width: 100%;
  padding: 0 30px;
}


.anime-title,
.watch-anime,
.anime-storyline {
  margin-left: 30px;
}

.anime-title {
  font-size: 40px;
  font-weight: 500;
  margin-bottom: 40px;
  letter-spacing: .1px;
}

.watch-anime-btn {
  background-color: var(--button-color);
  border: none;
  padding: 19px 50px 19px;
  font-size: 18px;
  font-weight: 500;
  border-radius: 10px;
  margin-bottom: 40px;
  margin-left: 30px;
  color: var(--text-color);
  text-align: center;
}

.anime-storyline {
  background-color: var(--episode-summary);
  padding: 15px;
  border-radius: 8px;
  max-height: 200px;
  overflow-y: scroll;
  padding-bottom: 40px;
  margin-left: 30px;
}

.anime-storyline>.summary {
  font-size: 25px;
  margin: 15px 0;
  word-spacing: 5px;
  font-weight: 600;
}

.anime-storyline>p {
  font-size: 18px;
  font-weight: 500;
  max-width: 900px;
}
.episode-detail-container{
  display: flex;
  justify-content: center;
  align-items: center;
}
.anime-episodes {
  background-color: var(--stream-background-color);
  padding: 20px 30px;
  border-radius: 10px;
  flex-direction: row;
  margin-top: 5%;
  flex-wrap: wrap;
  max-height: 400px;
  max-width: 900px;
  overflow: auto;
}
.anime-episodes::-webkit-scrollbar{
  display: none;
}
.anime-episodes .episodes-list{
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  overflow-x: hidden;

}
.anime-episodes .episodes-list button {
  width: 55px;
  padding: 10px;
  margin: 10px 0px;
  font-size: 15px;
  font-weight: 600;
  background-color: var(--episode-button-color);
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}




@media only screen and (max-width: 1500px) {
  .details {
    padding: 2rem 4rem;
  }


  .episode-list.details {
    margin-left: 0px;
  }

  .episode-list.details::-webkit-scrollbar-thumb {
    display: none;
  }

  .anime-details {
    width: 100%;
  }
}

@media(max-width:1300px) {
  .details {
    padding: 1rem 0px;
  }
  .recommended-anime h1{
    padding: 0px 4%;
  }
}

@media (max-width: 920px) {
  .watch-anime {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .watch-anime-btn {
    margin-left: 0px;
  }

  .anime-details {
    width: 100%;
    flex-direction: column;
  }

  .anime-img .anime-image {
    width: 300px;
  }

  .anime-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }



  .anime-title,
  .watch-anime,
  .anime-storyline {
    margin: 15px 0;
  }

  .anime-title {
    font-size: 25px;
    text-align: center;
    /* background-color: red; */
    max-width: 600px;
  }

  .anime-trailer{
    display: flex;
    justify-content: center;
    align-items: center;
  }

}



@media(max-width:600px) {
  .recommended-anime h1 {
    font-size: 30px;
  }

  .anime-trailer iframe{
    max-width: 700px;
    max-height: 500px;
    width: 300px;
    height: 200px;
  }
}

@media only screen and (max-width: 520px) {
  .anime-img .anime-image {
    width: 200px;
    height: 300px;
  }

  .anime-title {
    font-size: 20px;
  }

  .watch-anime-btn {
    padding: 15px 20px;
  }
}

@media(max-width:450px) {
  .recommended-anime h1 {
    font-size: 25px;
  }
}

@media(max-width:370px) {
  .anime-info {
    padding: 0 0px;
  }

  .recommended-anime h1 {
    font-size: 20px;
  }

  .watch-anime-btn {
    padding: 10px;
  }
}